import React from 'react';
import './Ratings.css';

// Import images
import rating1 from 'C:/Users/User/iamcat.com/src/assets/ratings01.png';
import rating2 from 'C:/Users/User/iamcat.com/src/assets/ratings02.png';
import rating3 from 'C:/Users/User/iamcat.com/src/assets/ratings03.png';
import pegi3 from 'C:/Users/User/iamcat.com/src/assets/pegi03.png';
import star from 'C:/Users/User/iamcat.com/src/assets/star.png';

const ratings = [
  rating1,
  rating2,
  rating3,
];

const Ratings = () => {
  return (
    <div className="ratings-section">
      <div className="ratings-header">
        <img src={pegi3} alt="PEGI 3" className="pegi-image" />
        <div className="text-stars-container">
          <div className="stars-container">
            {[...Array(5)].map((_, index) => (
              <img key={index} src={star} alt="Star" className="star-image" />
            ))}
          </div>
          <div className="text-container">
            <p className="top-rated-text">Top rated on Meta Store</p>
            <p className="number-rated-text">(51272)</p>
          </div>
        </div>
      </div>
      <div className="ratings-container">
        {ratings.map((src, index) => (
          <div className="rating-item" key={index}>
            <img src={src} alt={`Rating ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ratings;
